<template>
  <div>
    <!-- <div style="width: 250px; padding: 20px">
      <el-input :value="phone" placeholder="请输入手机">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div> -->
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline"
      style="padding: 20px 20px 0"
    >
      <el-form-item label="创作者地址">
        <el-autocomplete
          v-model="queryForm.creator_address"
          placeholder="请输入创作者地址"
          :trigger-on-focus="false"
          value-key="text"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="专辑名称">
        <el-input v-model="queryForm.collection_name" placeholder="请输入专辑名称" />
      </el-form-item>
      <el-form-item label="Mint地址">
        <el-input v-model="queryForm.mint_address" placeholder="请输入Mint地址" />
      </el-form-item>

      <el-form-item label="提审日期">
        <el-date-picker
          type="date"
          placeholder="请选择提审日期"
          v-model="queryForm.time_start"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
        />
        <!-- <el-input v-model="queryForm.time_start" placeholder="请输入" /> -->
      </el-form-item>

      <el-form-item label="开售状态">
        <el-select v-model="queryForm.sale_state" placeholder="请选择开售状态">
          <el-option label="All" value="" />
          <el-option label="Pause" value="paused" />
          <el-option label="Public" value="public" />
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select v-model="queryForm.audit_state" placeholder="请选择审核状态">
          <el-option label="全部" value="" />
          <el-option label="待审核" value="audit" />
          <el-option label="已通过" value="passed" />
          <el-option label="已拒绝" value="reject" />
        </el-select>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="queryForm.collection_type" placeholder="请选择类型">
          <el-option label="全部" value="" />
          <el-option label="正常专辑" value="normal" />
          <el-option label="递归专辑" value="recursive" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table
        :data="data"
        border
        stripe
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :selectable="selectable" width="40" />
        <el-table-column label="封面" align="center">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.cover"
              fit="fill"
              style="width: 200px; height: 200px; object-fit: cover;"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-for="col in columns"
          :prop="col.id"
          :key="col.id"
          :label="col.label"
          :width="col.width"
          :sortable="col.sortable"
          :formatter="col.formatter"
        />

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- v-if="scope.row.status === 'audit'" -->
            <el-button
              v-if="true"
              size="mini"
              type="primary"
              @click="handleEdit(scope.row)"
              style="margin-top: 8px"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog :visible.sync="showAdd" width="50%" @close="clearPosition" destroy-on-close>
      <div style="padding-left: 30px; padding-right: 30px;">
        <h2>专辑详情</h2>
        <el-image
          :src="dialogData.cover"
          fit="fill"
          style="width: 200px; height: 200px; object-fit: cover;"
        ></el-image>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>审核状态:</b></div>
          <span>{{ getStatusWord() }}</span>
        </div>
        <!-- <p>提审日期: {{ dayjs(dialogData.submit_at_sec * 1000).format("YYYY-MM-DD HH:mm:ss") }}</p> -->
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>专辑类型: </b></div>
          <span>{{ getCollectionTypeWord() }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>专辑名称: </b></div>
          <span>{{ dialogData.collection_name }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>专辑描述: </b></div>
          <span>{{ dialogData.description }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>创作者:</b></div>
          <span> {{ dialogData.creator_address }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>email:</b></div>
          <span> {{ dialogData.email }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>mint进度:</b></div>
          <span> {{ dialogData.minted + " / " + dialogData.supply }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>开售状态:</b></div>
          <span> {{ getSaleStatusWord() }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>Mint地址:</b></div>
          <span> {{ "" }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>版税:</b></div>
          <span> {{ dialogData.royalty }} %</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>Twitter link:</b></div>
          <span> {{ dialogData.twitter }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>Discord link:</b></div>
          <span> {{ dialogData.discord }}</span>
        </div>
        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>Website link:</b></div>
          <span> {{ dialogData.website }}</span>
        </div>

        <div style="display: flex; padding-top: 20px;">
          <div style="width: 100px; "><b>递归 link:</b></div>
          <span> {{ dialogData.recursion_link }}</span>
        </div>

        <div
          style="border: 1px solid #ccc;padding: 15px; margin-top: 20px; border-radius: 8px;"
          v-for="info in dialogData.white_list_info"
          :key="info.collection_id"
        >
          <h3>{{ info.name }}</h3>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>Mint阶段名称:</b></div>
            <span> {{ info.name }}</span>
          </div>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>Mint 单价:</b></div>
            <span> {{ info.price }}</span>
          </div>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>可mint日期:</b></div>
            <span>
              {{ formatDate(info.start_date * 1000) }} -
              {{ formatDate(info.end_date * 1000) }}</span
            >
          </div>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>单地址最大mint数:</b></div>
            <span> {{ info.limit }}</span>
          </div>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>mint白名单:</b></div>
            <span
              v-on:click="showWhiteListFunc(info.white_list)"
              style="color:blue; cursor: pointer;"
              >详情</span
            >
          </div>
        </div>

        <div style="border: 1px solid #ccc;padding: 15px; margin-top: 20px; border-radius: 8px;">
          <h3>Public</h3>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>Mint 单价:</b></div>
            <span> {{ dialogData.mint_price }}</span>
          </div>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>可mint日期:</b></div>
            <span>
              {{ formatDate(dialogData.start_mint_date * 1000) }} -
              {{ formatDate(dialogData.end_mint_date * 1000) }}</span
            >
          </div>
          <div style="display: flex; padding-top: 20px;">
            <div style="width: 150px; "><b>单地址最大mint数:</b></div>
            <span> {{ dialogData.mint_per_count }}</span>
          </div>
        </div>

        <div
          v-if="dialogData.collection_type == 'normal'"
          style="display: flex; padding-top: 20px;"
        >
          <div style="width: 250px; "><b> inscription List & Metadata</b></div>
          <a target="_blank" :href="dialogData.meta_file_url" :disabled="!dialogData.meta_file_url"
            >json文件下载</a
          >
        </div>

        <div
          v-if="dialogData.collection_type == 'normal'"
          style="display: flex; padding-top: 20px;"
        >
          <div style="width: 250px; "><b> Collection assets</b></div>
          <a target="_blank" :href="dialogData.zip_file_url" :disabled="!dialogData.zip_file_url"
            >图片ZIP下载</a
          >
        </div>
      </div>

      <span slot="footer">
        <el-button
          :disabled="dialogData.status !== 'audit'"
          type="error"
          @click="handleAudit('reject')"
          >拒 绝</el-button
        >
        <el-button
          :disabled="dialogData.status !== 'audit'"
          type="primary"
          @click="handleAudit('public')"
          >审核通过</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showWhiteList"
      width="50%"
      @close="clearWhiteListFunc"
      destroy-on-close
    >
      <div v-for="address in whiteList" :key="address">
        {{ address }}
      </div>
      <span slot="footer">
        <el-button type="primary" @click="clearWhiteListFunc">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLaunchPadCollection,
  getLaunchPadCollectionDetail,
  auditPadCollection,
} from "@/api/nft";
import dayjs from "dayjs";
import { StartTime, EndTime } from "@/utils/helper.js";
import { Message } from "element-ui";

const mapOrderToId = (order) => order.order_id;
const tableSortOrder2SortType = (val) => {
  if (val === "descending") {
    return "desc";
  }
  if (val === "ascending") {
    return "asc";
  }
  return "";
};

export default {
  data() {
    return {
      queryForm: {},
      page: 1,
      pageSize: 20,
      sortParams: {
        sort_field: "timestamp",
        sort_type: "desc",
      },
      total: 0,
      selectOrders: [],
      data: [],
      dialogData: {},
      whiteList: [],
      showAdd: false,
      showWhiteList: false,
      columns: [
        // {
        //   id: "cover",
        //   label: "封面",
        //   // width: 120,
        //   formatter: (row) => {
        //     return <el-image src="" />;
        //     //     return  (<el-image
        //     //   :src="row.cover"
        //     //   fit="fill"
        //     //   style="width: 200px; height: 200px; object-fit: cover;"
        //     // />);
        //   },
        // },
        {
          id: "type",
          label: "类型",
          // sortable: "custom",
          // width: 110,
          // formatter: (row) => dayjs(+row.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss"),
          formatter: (row) => {
            if (row.collection_type == "normal") {
              return "正常";
            }
            if (row.collection_type == "recursive") {
              return "递归";
            }
          },
        },
        {
          id: "collection_name",
          label: "专辑名称",
          // width: 180,
          minWidth: 180,
          formatter: (row) => {
            return row.name;
          },
        },
        {
          id: "sale_status",
          label: "开售状态",
          // width: 120,
          formatter: (row) => {
            if (row.status == "public") {
              return "Public";
            } else {
              return "Paused";
            }
          },
        },
        {
          id: "mint_address",
          label: "mint地址",
          // width: 80,
          // formatter: (row) => {
          //   return row.is_certify ? (
          //     <el-tag size="small" type="success">
          //       已认证
          //     </el-tag>
          //   ) : (
          //     <el-tag size="small">未认证</el-tag>
          //   );
          // },
        },
        {
          id: "progress",
          label: "mint进度",
          // width: 80,
          formatter: (row) => {
            return row.minted + " / " + row.supply;
          },
        },
        {
          id: "creator",
          label: "创作者",
          // width: 120,
          formatter: (row) => {
            let address = row.creator_address;
            if (address.length > 5) {
              address =
                address.substring(0, 4) +
                "...." +
                address.substring(address.length - 4, address.length);
            }
            return address;
          },
        },
        // {
        //   id: "realname",
        //   label: "姓名",
        // },
        {
          id: "amount",
          label: "提审日期",
          // sortable: "custom",
          // width: 110,
          formatter: (row) => dayjs(+row.submit_at_sec * 1000).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          id: "adult_state",
          label: "审核状态",
          formatter: (row) => {
            if (row.status == "audit") {
              return "待审核";
            } else if (row.status == "public" || row.status == "paused") {
              return "已通过";
            } else if (row.status == "reject") {
              return "已拒绝";
            }
          },
        },
      ],
    };
  },
  computed: {
    queryParams() {
      let time_start = undefined;
      // let status = this.queryForm.sale_state;
      // let audit_status = "";
      // if (this.queryForm.sale_state && this.queryForm.sale_state !== "all") {
      //   status = this.queryForm.sale_state;
      // }
      // if (this.queryForm.audit_state && this.queryForm.audit_state !== "all") {
      //   audit_status = this.queryForm.audit_state;
      // }

      if (this.queryForm.time_start) {
        time_start = StartTime(this.queryForm.time_start);
      }

      return {
        status: this.queryForm.sale_state,
        audit_status: this.queryForm.audit_state,
        creator_address: this.queryForm.creator_address,
        collection_type: this.queryForm.collection_type,
        submit_at_sec: time_start,
        name: this.queryForm.collection_name,
        page: this.page,
        page_size: this.pageSize,
      };
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const id = search.get("ucid");
    if (id) {
      this.queryForm = {
        ...this.queryForm,
        customer_ucid: id,
      };
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const params = { ...this.queryParams };
      // delete params.phone;

      // console.log("params = ", params);

      try {
        const { data } = await getLaunchPadCollection(params);
        const { total = 0, items = [] } = data?.data;

        this.data = [...items];
        this.total = total;
      } catch (error) {
        console.log(error);
      }
    },

    getStatusWord() {
      if (this.dialogData.status == "audit") {
        return "审核中";
      } else if (this.dialogData.status == "public" || this.dialogData.status == "paused") {
        return "已通过";
      } else if (this.dialogData.status == "reject") {
        return "已拒绝";
      }

      return "";
    },
    getSaleStatusWord() {
      if (this.dialogData.status == "public") {
        return "Public";
      } else {
        return "Paused";
      }
    },
    getCollectionTypeWord() {
      if (this.dialogData.collection_type == "normal") {
        return "正常专辑";
      } else if (this.dialogData.collection_type == "recursive") {
        return "递归专辑";
      }
    },

    async getCollectionDetail(collection_id) {
      const params = {
        collection_id: collection_id,
      };
      // delete params.phone;

      // console.log("params = ", params);

      try {
        const { data } = await getLaunchPadCollectionDetail(params);
        // const { total = 0, items = [] } = data?.data;
        this.dialogData = data?.data;

        // console.log("dialogData = ", this.dialogData);
      } catch (error) {
        console.log(error);
      }
    },

    async handleEdit(data) {
      await this.getCollectionDetail(data.collection_id);
      this.showAdd = true;
    },

    clearPosition() {
      this.showAdd = false;
      this.dialogData = {};
    },

    formatDate(timestamp) {
      return dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
    },

    async handleAudit(status) {
      const params = {
        collection_id: this.dialogData.collection_id,
        status: status,
      };
      // delete params.phone;

      // console.log("params = ", params);

      try {
        const { data } = await auditPadCollection(params);
        // const { total = 0, items = [] } = data?.data;

        Message.success({
          message: "操作成功",
          duration: 2000,
        });
        // 没有报错就刷新界面
        window.location.reload();

        // this.clearPosition();
      } catch (error) {
        console.log(error);
        Message.error({
          message: "操作失败" + error,
          duration: 2000,
        });
      }
    },
    showWhiteListFunc(white_list) {
      this.showWhiteList = true;
      this.whiteList = white_list;
    },
    clearWhiteListFunc() {
      this.showWhiteList = false;
      this.whiteList = [];
    },

    // TODO 下面的需要筛选

    handleInputUcid(val) {
      this.queryForm = {
        ...this.queryForm,
        phone: "",
      };
      return val;
    },
    handleInputAddress(item) {
      // console.log("handleInputAddress = ", item);
      this.queryForm = {
        ...this.queryForm,
        // address: item.value,
      };
      return item;
    },
    handleSortChange(e) {
      const { prop, order } = e;
      // 默认时间降序
      if (order === null) {
        this.sortParams = {
          sort_field: "timestamp",
          sort_type: "desc",
        };
      } else {
        this.sortParams = {
          sort_field: prop,
          sort_type: tableSortOrder2SortType(order),
        };
      }
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.selectOrders = val;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
    onClear() {
      this.page = 1;
      this.pageSize = 20;
      this.queryForm = {};
      this.fetchData();
    },
    selectable(row) {
      return +row.state === -1 && +row.channel != 15;
    },
  },
};
</script>

<style lang="less">
.number-input.el-input-number .el-input__inner {
  text-align: left;
}

.stage-out {
  border: 1px solid #ccc;
  padding: 15px;
}
</style>
